/** @jsx jsx */
import { useState } from "react";
import { Box, Input, Button, jsx, Card, Themed } from "theme-ui";

import Layout from "../components/layout";
import Organizers from "../components/organizers";
import Seo from "../components/seo";

export default function IndexPage() {
  const [loading, setLoading] = useState(false);
  return (
    <Layout>
      <Card sx={{ m: "auto", maxWidth: "container" }}>
        <Organizers />
        <Themed.h2 sx={{ textAlign: "center", mt: 0 }}>Invitan a la noche de cine</Themed.h2>
        <Themed.h2 sx={{ textAlign: "center" }}>🍿🍿🍿</Themed.h2>
        <Themed.p sx={{ textAlign: "center" }}>
          Hola queremos contarte que esta es una actividad profondos y nos agrada que estés aquí. La entrada cuesta{" "}
          <strong>$3000</strong> pesos y tiene un paquete de <strong>cabritas de regalo</strong>.
        </Themed.p>
        <Box
          as="form"
          style={{ display: "flex", flexDirection: "column" }}
          method="POST"
          action="/api/create"
          onSubmit={() => setLoading(true)}
        >
          <Input id="name" name="name" type="text" placeholder="Nombre" required />
          <Input id="email" name="email" type="email" placeholder="Email" required />
          <Button disabled={loading} style={{ marginTop: 3 }} variant={loading ? "disabled" : "primary"}>
            {loading ? "Enviando..." : "Comprar entrada ($3000)"}
          </Button>
        </Box>
      </Card>
    </Layout>
  );
}

export const Head = () => <Seo />;
